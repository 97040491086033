import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FileSaver from "file-saver";
import * as Styles from "../styles";

function PersonDetails() {
  const { slug } = useParams();
  const [person, setPerson] = useState(null);
  const [image, setImage] = useState(null);
  // const [base64String, setBase64String] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://people.matrixrelo.com/wp-json/wp/v2/posts?_embed&slug=${slug}`,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          setPerson(response.data);

          const getImage = () => {
            axios
              .get(response?.data[0]?._links["wp:featuredmedia"][0]?.href)
              .then((response) => {
                setImage(response.data.source_url);
              })
              .catch((error) => {
                // console.error(error);
                // return false;
                // if (error.response) {
                //   console.log(error.response.data);
                //   console.log(error.response.status);
                //   console.log(error.response.headers);
                // } else if (error.request) {
                //   console.log(error.request);
                // } else {
                //   console.log('Error', error.message);
                // }
                // console.log(error.config);
                console.log("Error", error.message);
              });
          };

          getImage();
        }
      })
      .catch((error) => {
        // console.error(error);
        // return false;
        // if (error.response) {
        //   console.log(error.response.data);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
        // } else if (error.request) {
        //   console.log(error.request);
        // } else {
        //   console.log('Error', error.message);
        // }
        // console.log(error.config);
        console.log("Error", error.message);
      });
  }, [slug]);

  if (!person?.length) {
    return <div>Loading...</div>;
  }

  const fullName = person[0].title.rendered.split(" ");
  const firstName = fullName[0];
  const lastName = fullName[1];
  const organization = person[0].acf.organization;
  const position = person[0].acf.position;
  const email = person[0].acf.email;
  const mobileNum = person[0].acf.mobile;
  const workNum = person[0].acf.telephone;
  const address = person[0].acf.address;

  function handleClick(e) {
    e.preventDefault();

    var file = new Blob(
      [
        `BEGIN:VCARD
VERSION:3.0
N:${lastName};${firstName};;;
FN:${firstName} ${lastName}
ORG:${organization};
TITLE:${position};
EMAIL;type=INTERNET;type=pref:${email}
TEL;type=MAIN:${workNum}
TEL;type=CELL;type=VOICE;type=pref:${mobileNum}
ADR;type=WORK;type=pref:;;;${address};;;
END:VCARD`,
      ],
      { type: "text/vcard;charset=utf-8" }
    );
    FileSaver.saveAs(file, `${firstName}${lastName}.vcf`, true);
  }

  return (
    <div className={Styles.vcard}>
      {person.map((p, index) => {
        const parts = p.acf.address.split(", ");

        return (
          <React.Fragment key={index}>
            <div className={Styles.vcardHeader}>
              <div className={Styles.vcardHeaderInner}>
                <div className={Styles.vcardHeaderInfo}>
                  {image && <img src={image} alt={p.title.rendered} />}

                  <h2>{p.title.rendered}</h2>

                  {p.acf.position && <p>{p.acf.position}</p>}
                </div>

                <div className={Styles.vcardHeaderCta}>
                  {p.acf.mobile && (
                    <a
                      href={`tel:${p.acf.mobile}`}
                      className={Styles.vcardHeaderCtaLink}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="18px"
                        viewBox="0 0 512 512"
                      >
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                      </svg>
                      <span>Call</span>
                    </a>
                  )}

                  {p.acf.email && (
                    <a
                      href={`mailto:${p.acf.email}`}
                      className={Styles.vcardHeaderCtaLink}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="18px"
                        viewBox="0 0 512 512"
                      >
                        <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
                      </svg>
                      <span>Email</span>
                    </a>
                  )}

                  {p.acf.address && (
                    <a
                      href={`https://maps.google.com/?q=${p.acf.address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={Styles.vcardHeaderCtaLink}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="18px"
                        viewBox="0 0 384 512"
                      >
                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                      </svg>
                      <span>Directions</span>
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className={Styles.vcardBody}>
              <div className={Styles.vcardBodyInner}>
                {p.acf.mobile && (
                  <div className={Styles.vcardBodyItem}>
                    <div className={Styles.vcardBodyItemIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                      </svg>
                    </div>

                    <div className={Styles.vcardBodyItemContent}>
                      <a href={`tel:${p.acf.mobile}`}>{p.acf.mobile}</a>
                      <span>Mobile</span>
                    </div>
                  </div>
                )}

                {p.acf.telephone && (
                  <div className={Styles.vcardBodyItem}>
                    <div className={Styles.vcardBodyItemIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                      </svg>
                    </div>

                    <div className={Styles.vcardBodyItemContent}>
                      <a href={`tel:${p.acf.telephone}`}>{p.acf.telephone}</a>
                      <span>Work Phone</span>
                    </div>
                  </div>
                )}

                {p.acf.email && (
                  <div className={Styles.vcardBodyItem}>
                    <div className={Styles.vcardBodyItemIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                      </svg>
                    </div>

                    <div className={Styles.vcardBodyItemContent}>
                      <a href={`mailto:${p.acf.email}`}>{p.acf.email}</a>
                      <span>Email</span>
                    </div>
                  </div>
                )}

                {(p.acf.organization || p.acf.position) && (
                  <div className={Styles.vcardBodyItem}>
                    <div className={Styles.vcardBodyItemIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z" />
                      </svg>
                    </div>

                    <div className={Styles.vcardBodyItemContent}>
                      {p.acf.organization && <p>{p.acf.organization}</p>}
                      {p.acf.position && <span>{p.acf.position}</span>}
                    </div>
                  </div>
                )}

                {p.acf.address && (
                  <div className={Styles.vcardBodyItem}>
                    <div className={Styles.vcardBodyItemIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                      </svg>
                    </div>

                    <div className={Styles.vcardBodyItemContent}>
                      <p>
                        {parts.map((part, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {part + ", "}
                          </React.Fragment>
                        ))}
                      </p>
                      <span>Business Address</span>
                      <a
                        href={`https://maps.google.com/?q=${p.acf.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={Styles.vcardBodyItemShowOnMap}
                      >
                        Show on map
                      </a>
                    </div>
                  </div>
                )}

                {p.acf.website && (
                  <div className={Styles.vcardBodyItem}>
                    <div className={Styles.vcardBodyItemIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                      </svg>
                    </div>

                    <div className={Styles.vcardBodyItemContent}>
                      <a
                        href={p.acf.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {p.acf.website}
                      </a>
                      <span>Website</span>
                    </div>
                  </div>
                )}

                {(p.acf.linkedin || p.acf.facebook || p.acf.twitter) && (
                  <div className={Styles.vcardBodySocialMedia}>
                    <h5>Social Media</h5>

                    <ul className={Styles.vcardBodySocialMediaList}>
                      {p.acf.linkedin && (
                        <li className={Styles.vcardBodySocialMediaListItem}>
                          <a
                            href={p.acf.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="linkedin"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                            </svg>
                          </a>
                        </li>
                      )}

                      {p.acf.facebook && (
                        <li className={Styles.vcardBodySocialMediaListItem}>
                          <a
                            href={p.acf.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="facebook"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                            </svg>
                          </a>
                        </li>
                      )}

                      {p.acf.twitter && (
                        <li className={Styles.vcardBodySocialMediaListItem}>
                          <a
                            href={p.acf.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="twitter"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                            </svg>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                <div className={Styles.vcardDownloadCta}>
                  <button
                    onClick={(e) => handleClick(e)}
                    className={Styles.vcardDownloadButton}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="#ffffff"
                        d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
                      />
                    </svg>
                    <span>Download vCard</span>
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default PersonDetails;
