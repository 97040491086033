import { css } from '@emotion/css';

export const vcard = css`
  background-color: #fff;
`;

export const vcardHeader = css`
  background-color: #404040;
`;

export const vcardHeaderInner = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 -5px 40px 7px rgba(0,0,0,0.08);
`;

export const vcardHeaderInfo = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0 20px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 100%;
  }

  h2 {
    margin: 0;
    padding: 20px 0 15px; 
    color: #fff;
  }

  p {
    margin: 0;
    color: #fff;
  }
`;

export const vcardHeaderCta = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const vcardHeaderCtaLink = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  border-top: 1px solid rgba(255, 255, 255, .15);
  background-color: #404040;

  &:nth-of-type(even) {
    border-left: 1px solid rgba(255, 255, 255, .15);;
    border-right: 1px solid rgba(255, 255, 255, .15);;
  }

  svg {
    margin-bottom: 10px;
    transition: all .3s ease-in-out;

    path {
      fill: #fff;
    }
  }

  &:hover {
    background-color: #363636;

    svg {
      transform: scale(1.1);
    }
  }
`;

export const vcardBody = css`
  background-color: #fff;
`;

export const vcardBodyInner = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 45px 40px;
  box-shadow: 0 -5px 40px 7px rgba(0,0,0,0.08);
  box-sizing: border-box;
`;

export const vcardBodyItem = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 25px;
  padding: 20px 0;
`;

export const vcardBodyItemIcon = css`
  width: 30px;
  flex-shrink: 0;

  svg {
    height: 30px;

    path {
      fill: #9a9a9a;
    }
  }
`;

export const vcardBodyItemContent = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  a {
    color: #000;
    text-decoration: none;
    transition: all .3s ease-in-out;

    &:hover {
      color: #ef0000;
    }
  }

  p {
    margin: 0;
  }

  span {
    margin-top: 5px;
    font-size: ;
    color: #82848f;
  }
`;

export const vcardBodyItemShowOnMap = css`
  display: block;
  margin-top: 20px;
  padding: 10px 15px;
  color: #fff !important;
  background-color: #ef0000 !important;
  text-transform: uppercase;
  box-shadow: 0 0 12px 6px rgba(239,0,0,0.08);
  transition: all .3s ease-in-out;

  &:hover {
    color: #ef0000 !important;
    background-color: #fff !important;
  }
`;

export const vcardBodySocialMedia = css`
  width: 100%;

  h5 {
    color: #82848f;
    font-size: 18px;
  }
`;

export const vcardBodySocialMediaList = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const vcardBodySocialMediaListItem = css`
  display: block;

  a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 100%;

    svg {
      width: auto;
      height: 25px;
      transition: all .3s ease-in-out;

      path {
        fill: #fff;
      }
    }

    &:hover {
      
      svg {
        transform: scale(1.1);
      }
    }

    &.linkedin {
      background-color: #0a66c2;
    }

    &.facebook {
      background-color: #1877f2;
    }

    &.twitter {
      background-color: #1da1f2;
    }
  }
`;

export const vcardDownloadCta = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  border: 0;
`;

export const vcardDownloadButton = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  width: auto;
  padding: 10px 45px;
  background-color: #ef0000;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  border: 0;

  svg {
    width: 22px;
    height: 22px;
  }
`;