import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import ContactCard from './ContactCard';
import PersonDetails from './pages/PersonDetails';

function App() {
  return (
		<Routes>
			<Route path="/:slug" element={<PersonDetails/>} />
		</Routes>
  );
}

export default App;